import {
  type ButtonProps,
  Box,
  Button,
  Card,
} from '@iheartradio/web.companion';
import type {
  ComponentProps,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from 'react';
import type { SetOptional } from 'type-fest';

export type ContentCardProps = {
  active?: boolean;
  button?: ReturnType<typeof Button>;
  image: ReturnType<typeof Card.Image>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  imageButton?: ReactNode;
  subtitle?: string;
  title?: string;
  linesForTitle?: number;
};

export const ContentCard = (props: ContentCardProps) => {
  const {
    button,
    image,
    subtitle,
    onClick,
    title,
    active = false,
    linesForTitle = 1,
    imageButton = null,
  } = props;

  return (
    <Box height="$12" padding="$2 $2 $2 0">
      <Card.Root
        data-test="content-card"
        gap="$12"
        isActive={active}
        isLink
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          const target = event.target as HTMLDivElement;

          if (
            target instanceof HTMLAnchorElement ||
            target instanceof HTMLButtonElement
          ) {
            event.stopPropagation();
            return;
          }

          onClick?.(event);
        }}
        padding={{ '@large': '$6' }}
        tabIndex={0}
      >
        <Card.ImageContainer
          aspectRatio="1 / 1"
          borderRadius="inherit"
          css={{
            [`& > ${Card.Image}`]: {
              aspectRatio: '1 / 1',
            },
          }}
        >
          {image}
          <Card.ImageButton
            onClick={(event: MouseEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            {imageButton}
          </Card.ImageButton>
        </Card.ImageContainer>
        {title || subtitle ? (
          <Card.Body>
            {title ? (
              <Card.Title
                color={
                  active ? { light: '$red-600', dark: '$red-300' } : undefined
                }
                lines={linesForTitle}
              >
                {title}
              </Card.Title>
            ) : null}
            {subtitle ? <Card.Subtitle>{subtitle}</Card.Subtitle> : null}
          </Card.Body>
        ) : null}
        {button ? (
          <Card.Actions
            css={{
              '& > *': { width: '100%' },
              'margin-top': 'auto',
            }}
          >
            {button}
          </Card.Actions>
        ) : null}
      </Card.Root>
    </Box>
  );
};

ContentCard.Button = function ContentCardButton(
  props: SetOptional<ButtonProps, 'kind' | 'color' | 'size'>,
) {
  return (
    <Button
      inline={false}
      kind="secondary"
      size="small"
      {...props}
      color={{ dark: 'white', light: 'gray' }}
      css={{ textAlign: 'center', flexGrow: 1, justifyContent: 'center' }}
    />
  );
};

ContentCard.Image = function ContentCardImage(
  props: ComponentProps<typeof Card.Image>,
) {
  return <Card.Image aspectRatio="1 / 1" width="100%" {...props} />;
};
